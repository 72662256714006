import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <div className="content">
                    <h1> Personal Fun Projects</h1>
                    <div className="card main-project">
                      <div className="card-image">
                        <img
                          src="https://i.imgur.com/lfunsFL.png"
                          alt="CinematicPitcher.png"
                        />
                      </div>
                      <div className="card-content">
                        <div className="media">
                          <div className="media-content">
                            <a href="https://cinematicpitcher.now.sh/">
                              Cinematic Pitcher
                            </a>
                          </div>
                        </div>
                        <div className="card-content">
                          Web App where you can view movie info and trailers
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <section className="section">
          <div className="container">
            <div className="content">
              <h1> Personal Fun Projects</h1>
              <div className="card main-project">
                <div className="card-image">
                  <img
                    src="https://i.imgur.com/lfunsFL.png"
                    alt="CinematicPitcher.png"
                  />
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <a href="https://cinematicpitcher.now.sh/">
                        Cinematic Pitcher
                      </a>
                    </div>
                  </div>

                  <div className="card-content">
                    Web App where you can view movie info and trailers
                  </div>
                </div>
              </div>
            </div>
            <div className="content project-2">
              <div className="card">
                <div className="card-image">
                  <img
                    src="https://picsum.photos/400/138"
                    alt="CinematicPitcher.png"
                  />
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <a href="https://cinematicpitcher.now.sh/">TBD</a>
                    </div>
                  </div>
                  <div className="card-content">TBD</div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </Layout>
    );
  }
}
